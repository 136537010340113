import { SuperAdminViewModal } from 'features/withdraw/components/modal/super-view-data';
import { useNavigate, useParams } from 'react-router-dom';
import { ROUTES } from 'routes/constants';
// ../components/modal/withdraw-detail
// ../components/modal/withdraw-view-data
// ../components/modal/super-view-data
export const SuperAdminViewDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  if (!id) return null;

  const closeModal = () => {
    navigate(ROUTES.SUPER_ADMIN);
  };

  return (
    <SuperAdminViewModal id={id} onClose={closeModal} onSuccess={closeModal} />
  );
};
