import { message } from 'antd';
import { ContentTopBar } from 'components';
import {
  MainFilters,
  MainFiltersParams,
} from 'components/main-filters/main-filters';
import dayjs, { Dayjs } from 'dayjs';
import {
  GetReportDTO,
  Reports,
  useGetAccountsReport,
  useGetConsolidatedReport,
  useGetSalesReport,
} from 'features/reports';
import { useGetCancelReport } from 'features/reports/use-cases/get-cancel-report';
import { useGetCustomizeAccountsReport } from 'features/reports/use-cases/get-customize-report';
import { useGetPackageReport } from 'features/reports/use-cases/get-package-report';
import { useGetWalletReport } from 'features/reports/use-cases/get-wallet-report';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import { formatDate } from 'utils/date';
// ../use-cases/get-wallet-report
// ../use-cases/get-customize-report
// ../use-cases/get-cancel-report
// ../use-cases/get-package-report
const getPayload = ({
  fromDate,
  toDate,
  queryParams,
}: {
  fromDate: Dayjs;
  toDate: Dayjs;
  queryParams?: MainFiltersParams;
}) => {
  const payload = {
    data: {
      from_date: formatDate({
        date: dayjs(fromDate).startOf('day'),
        format: 'dbDate',
      }),
      to_date: formatDate({
        date: dayjs(toDate).endOf('day'),
        format: 'dbDate',
      }),
    },
    params: queryParams,
  };

  return payload;
};

export const ReportsView = () => {
  const [queryParams, setQueryParams] = useState<MainFiltersParams>();
  const [loading, setLoading] = useState({
    loading: false,
    loading1: false,
    loading2: false,
    loading3: false,
    loading4: false,
    loading5: false,
    loading6: false,
  });
  const [queryFilters, setQueryFilters] = useState<
    [Dayjs | null, Dayjs | null] | null
  >();
  const { t } = useTranslation();
  const { downloadConsolidatedReport } = useGetConsolidatedReport();
  const { downloadCancelReport } = useGetCancelReport();
  const { downloadCustmizeAccountsReport } = useGetCustomizeAccountsReport();

  const { downloadAccountsReport } = useGetAccountsReport();
  const { downloadSalesReport } = useGetSalesReport();
  const { downloadPackageReport } = useGetPackageReport();
  const { downloadWalletReport } = useGetWalletReport();

  const handleParams = ({ company_type, company_id }: MainFiltersParams) => {
    setQueryParams({
      company_type,
      company_id,
    });
  };

  const handleDate = (date: [Dayjs | null, Dayjs | null] | null) =>
    setQueryFilters(date);

  const handleGetReport = (getReport: (payload: GetReportDTO) => void) => {
    if (queryFilters && !!queryFilters[0] && !!queryFilters[1]) {
      const fromDate = queryFilters[0];
      const toDate = queryFilters[1];

      const payload = getPayload({ queryParams, fromDate, toDate });

      return getReport(payload);
    } else {
      message.error('Please select date');
    }
  };

  const handleConsolidatedReport = async () => {
    setLoading({
      ...loading,
      loading3: true,
    });
    // return handleGetReport(downloadConsolidatedReport);
    await handleGetReport(downloadConsolidatedReport);
    setLoading({
      ...loading,
      loading3: false,
    });
  };
  const handleCancelReport = async () => {
    setLoading({
      ...loading,
      loading2: true,
    });
    // return handleGetReport(downloadCancelReport);
    await handleGetReport(downloadCancelReport);
    setLoading({
      ...loading,
      loading2: false,
    });
  };

  const handleAccountsReport = async () => {
    setLoading({
      ...loading,
      loading1: true,
    });
    // return handleGetReport(downloadAccountsReport);
    await handleGetReport(downloadAccountsReport);
    setLoading({
      ...loading,
      loading1: false,
    });
  };
  const handleCustomizeAccountsReport = async () => {
    setLoading({
      ...loading,
      loading4: true,
    });
    // return handleGetReport(downloadAccountsReport);
    await handleGetReport(downloadCustmizeAccountsReport);
    setLoading({
      ...loading,
      loading4: false,
    });
  };
  const handleSalesReport = async () => {
    // return handleGetReport(downloadSalesReport);
    setLoading({
      ...loading,
      loading: true,
    });
    await handleGetReport(downloadSalesReport);
    setLoading({
      ...loading,
      loading: false,
    });
  };
  const handlePackageReport = async () => {
    // return handleGetReport(downloadSalesReport);
    setLoading({
      ...loading,
      loading5: true,
    });
    await handleGetReport(downloadPackageReport);
    setLoading({
      ...loading,
      loading5: false,
    });
  };
  const handleWalletReport = async () => {
    // return handleGetReport(downloadSalesReport);
    setLoading({
      ...loading,
      loading6: true,
    });
    await handleGetReport(downloadWalletReport);
    setLoading({
      ...loading,
      loading6: false,
    });
  };

  return (
    <>
      <ContentTopBar
        title={t('reports.heading')}
        renderHeadingContent={
          <MainFilters
            onChange={handleParams}
            filterTypes={['companyType', 'company']}
          />
        }
      />
      <Reports
        handleDate={handleDate}
        handleConsolidatedReport={handleConsolidatedReport}
        handleAccountsReport={handleAccountsReport}
        handleSalesReport={handleSalesReport}
        handleCancelReport={handleCancelReport}
        handleCustomizeAccountsReport={handleCustomizeAccountsReport}
        handlePackageReport={handlePackageReport}
        handleWalletReport={handleWalletReport}
        loading={loading}
      />

      <Outlet />
    </>
  );
};
