import { Button, DatePicker, Form, Grid, Input, Select } from 'antd';
import { ContentFilters } from 'components';
import { StandardItemsWrapper } from 'components/content-filters/content-filters.styles';
import dayjs from 'dayjs';
import {
  UsersWithWithdrawFilterFormField,
  UsersWithWithdrawFilterFormValues,
} from 'features/withdraw/components/users-with-wallet-filter-form/type';
import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DATE_FORMATS } from 'utils/date';

const { RangePicker } = DatePicker;
const { useBreakpoint } = Grid;

type UsersWithWalletFilterFormProps = {
  formId: string;
  handleFilter: (values: UsersWithWithdrawFilterFormValues) => void;
  initialValues: UsersWithWithdrawFilterFormValues;
  setQueryFilters: Dispatch<SetStateAction<any>>;
  closeModal?: () => void;
};

export const UsersWithWithdrawFilterForm = ({
  formId,
  handleFilter,
  initialValues,
  setQueryFilters,
  closeModal,
}: UsersWithWalletFilterFormProps) => {
  const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
  const [form] = Form.useForm<UsersWithWithdrawFilterFormValues>();
  const { t } = useTranslation();
  const screens = useBreakpoint();

  const onReset = () => {
    form.resetFields();
    handleFilter({ ...initialValues });
  };

  const handleOnFinish = (values: UsersWithWithdrawFilterFormValues) => {
    handleFilter(values);
    setQueryFilters(values);
    closeModal?.();
  };

  return (
    <>
      <Form<UsersWithWithdrawFilterFormValues>
        id={formId}
        name={formId}
        form={form}
        layout='vertical'
        onFinish={handleOnFinish}
        initialValues={initialValues}
        onFinishFailed={() => {
          setValidateTrigger(['onChange']);
        }}
        validateTrigger={validateTrigger}
      >
        <ContentFilters.FormItemsWrapper>
          <StandardItemsWrapper>
            <Form.Item<UsersWithWithdrawFilterFormValues>
              label={t('withdraw.formfield.username.label')}
              name={UsersWithWithdrawFilterFormField.FullUserName}
            >
              <Input
                placeholder={t('withdraw.formfield.username.placeholder')}
              />
            </Form.Item>
            <Form.Item<UsersWithWithdrawFilterFormValues>
              label={t('withdraw.formfield.phonenumber.label')}
              name={UsersWithWithdrawFilterFormField.PhoneNumber}
            >
              <Input
                placeholder={t('withdraw.formfield.phonenumber.placeholder')}
              />
            </Form.Item>
            {/* <Form.Item<UsersWithWithdrawFilterFormValues>
              label={t('withdraw.formfield.referencenumber.label')}
              name={UsersWithWithdrawFilterFormField.ReferenceNumber}
            >
              <Input
                placeholder={t(
                  'withdraw.formfield.referencenumber.placeholder',
                )}
              />
            </Form.Item> */}
            <Form.Item<UsersWithWithdrawFilterFormValues>
              label={t('withdraw.formfield.status.label')}
              name={UsersWithWithdrawFilterFormField.status}
            >
              <Select
                style={{ width: 120 }}
                placeholder='All'
                options={[
                  { value: '1', label: 'Pending' },
                  { value: '2', label: 'Approved' },
                  { value: '3', label: 'Rejected' },
                ]}
              />
            </Form.Item>
            <Form.Item<UsersWithWithdrawFilterFormValues>
              label={t('withdraw.formfield.date.label')}
              name={UsersWithWithdrawFilterFormField.date}
            >
              <RangePicker
                format={DATE_FORMATS.date}
                presets={[
                  {
                    label: t('form.formFields.date.presets.today'),
                    value: [dayjs(), dayjs()],
                  },
                  {
                    label: t('form.formFields.date.presets.fromTomorrow'),
                    value: [dayjs().add(1, 'day'), null],
                  },
                  {
                    label: t('form.formFields.date.presets.untilYesterday'),
                    value: [null, dayjs().add(-1, 'day')],
                  },
                ]}
              />
            </Form.Item>
          </StandardItemsWrapper>
        </ContentFilters.FormItemsWrapper>

        {screens.lg && (
          <ContentFilters.ButtonsWrapper>
            <Button type='primary' htmlType='submit'>
              {t('wallet.buttons.filter', { count: 1 })}
            </Button>
            <Button onClick={onReset}>{t('wallet.buttons.reset')}</Button>
          </ContentFilters.ButtonsWrapper>
        )}
      </Form>
    </>
  );
};
