import { DatePicker, Space } from 'antd';
import gropicons from 'assets/icons/group.png';
import reloadicon from 'assets/icons/reload.png';
import { ContentTopBar } from 'components';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import timezone from 'dayjs/plugin/timezone';
import { DashboardApiService } from 'features/dashboard/api/dashboard-api.service';
import { Fragment, useEffect, useState } from 'react';
dayjs.extend(customParseFormat);
dayjs.extend(timezone);

export const DashBoardView = () => {
  const { RangePicker } = DatePicker;
  const [dateRange, setDateRange] = useState<[dayjs.Dayjs, dayjs.Dayjs] | null>(
    [dayjs(), dayjs()],
  );
  const [state, setState] = useState<any>('');

  const Initial = async () => {
    try {
      const fromDate = dateRange
        ? dateRange[0].startOf('day').tz('Asia/Kolkata').format('YYYY-MM-DD')
        : '';
      // THH:mm:ssZ
      const toDate = dateRange
        ? dateRange[1].endOf('day').tz('Asia/Kolkata').format('YYYY-MM-DD')
        : '';
      // THH:mm:ssZ
      // Send the formatted dates in the API request
      const apiRes = await DashboardApiService().getDashBoard({
        from_date: fromDate,
        to_date: toDate,
      } as any);
      setState(apiRes);
    } catch (error) {
      console.error('Error fetching dashboard data:', error);
    }
  };

  useEffect(() => {
    Initial(); // Call the API when the component mounts or the date range changes
  }, [dateRange]); // Dependency on dateRange to refetch when the date range changes

  const onDateChange = (dates: [dayjs.Dayjs, dayjs.Dayjs] | null) => {
    if (dates) {
      setDateRange(dates); // Update date range when the user selects a new date range
    } else {
      // If cleared, reset to today's date range
      setDateRange([dayjs(), dayjs()]);
    }
  };

  return (
    <Fragment>
      <ContentTopBar title={'DashBoard'} />
      <Space direction='vertical' className='card_row'>
        <RangePicker
          value={dateRange ? dateRange : [dayjs(), dayjs()]} // Show today's date if range is null
          onChange={onDateChange as any} // Handle date range changes
        />
        <div className='op_cl'>
          <h4>Wallet Report</h4>
          {state?.items?.map((item: any, index: number) => (
            <div key={index} className='op_cl_bx'>
              <div className='card_wrap' style={{ backgroundColor: '#015B97' }}>
                <div className='card_bx'>
                  <div>
                    <span>Opening Balance</span>
                    <h3>{item?.opening_balance}</h3>
                  </div>
                  <div className='bx_icon'>
                    <img src={gropicons} alt='' />
                  </div>
                </div>
              </div>

              <div className='card_wrap' style={{ backgroundColor: '#009EDB' }}>
                <div className='card_bx'>
                  <div>
                    <span>Closing Balance</span>
                    <h3>{item?.closing_balance}</h3>
                  </div>
                  <div className='bx_icon'>
                    <img src={reloadicon} alt='' />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </Space>
    </Fragment>
  );
};
