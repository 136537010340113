import { MoreOutlined } from '@ant-design/icons';
import { Dropdown, MenuProps, Space, TablePaginationConfig } from 'antd';
import { FilterValue, SorterResult } from 'antd/es/table/interface';
import { Column, RecordCountBadge, Table } from 'components';
import {
  StyledTableHeader,
  StyledTableWrapper,
} from 'components/table/table.styled';
import { usePermissions } from 'features/users';
import { UsersWithWithdrawFilterFormPaginated } from 'features/withdraw/components/users-with-wallet-filter-form/type';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'routes/constants';
import { getRoute } from 'routes/utils';
import { UserWithithdraw } from 'types/withdraw';
import { DEFAULT_TIMEZONE, formatDate } from 'utils/date';
// ../users-with-wallet-filter-form/type
// const { useBreakpoint } = Grid;

type UsersWithWalletTableProps = {
  isLoading: boolean;
  data?: any;
  tableParams: UsersWithWithdrawFilterFormPaginated;
  updateParams: Dispatch<SetStateAction<UsersWithWithdrawFilterFormPaginated>>;
};

export const UsersWithWithdrawTable = ({
  data,
  isLoading,
  tableParams,
  updateParams,
}: UsersWithWalletTableProps) => {
  const { t } = useTranslation();
  // const screens = useBreakpoint();
  const navigate = useNavigate();
  const permissions = usePermissions();

  if (!permissions) return null;

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<UserWithithdraw> | SorterResult<UserWithithdraw>[],
  ) => {
    const sortBy = () => {
      if (Array.isArray(sorter)) return;

      const sortOrder =
        sorter.order === 'ascend' ? `${sorter.field}` : `-${sorter.field}`;

      if (sorter?.field === 'last_transaction_date') {
        return {
          order_by_balance: undefined,
          order_by_transaction: sortOrder,
        };
      }
    };

    updateParams({
      ...tableParams,
      page: pagination.current,
      size: pagination.pageSize,
      ...sortBy(),
    });
  };
  const handleValue = (matchId: any) => {
    navigate(getRoute(ROUTES.WITHDRAW_detail, matchId));
  };
  const handleValueReject = (matchId: any) => {
    navigate(getRoute(ROUTES.WITHDRAW_REJECT, matchId));
  };
  const items = (id: any): MenuProps['items'] => {
    return [
      {
        label: 'Approved',
        onClick: () => handleValue(id),
        key: '0',
      },
      {
        label: 'Reject',
        onClick: () => handleValueReject(id),
        key: '1',
      },
    ];
  };
  // const tableWidth = screens.xxl ? { x: 1440 } : { x: 1100 };

  return (
    <StyledTableWrapper>
      <StyledTableHeader>
        <RecordCountBadge
          text={t('wallet.foundUsers')}
          count={data?.total || 0}
        />
      </StyledTableHeader>
      <Table<UserWithithdraw>
        loading={isLoading}
        dataSource={data?.items}
        rowKey='id'
        onChange={handleTableChange}
        pagination={{
          current: tableParams.page,
          defaultPageSize: tableParams.size,
          total: data?.total,
        }}
        onRow={(record: any) => {
          return {
            className: record.status_id !== 1 ? 'clickable-row' : '',
            onClick: () => {
              if (record.status_id !== 1) {
                navigate(getRoute(ROUTES.WITHDRAW_detail_data, record.id));
              }
            },
          };
        }}
      >
        <Column
          title={t('wallet.table.userName')}
          dataIndex={'user_name'}
          // width={screens.xxl ? 'auto' : 170}
          render={(_, record: any) => record.user_name}
        />
        <Column<UserWithithdraw>
          title={t('wallet.table.phone')}
          dataIndex={'phone_number'}
          // width={screens.xxl ? 'auto' : 170}
        />
        <Column<UserWithithdraw>
          title={t('withdraw.table.amount')}
          dataIndex={'amount'}
          // sorter={true}
          // sortDirections={['descend', 'ascend', 'descend']} // workaround to remove null as an option
          // width={screens.xxl ? 'auto' : 130}
          render={(_, record: any) => (
            <span>{`${record.amount ? `${record.amount} KD` : ''}`}</span>
          )}
        />
        <Column<UserWithithdraw>
          title={t('withdraw.table.Date')}
          dataIndex={'request_date'}
          render={(_, record: any) => (
            <>
              {formatDate({
                date: record.request_date,
                format: 'date',
                timeZone: DEFAULT_TIMEZONE,
              } as any)}
              <Space style={{ padding: '2px' }}>
                {formatDate({
                  date: record.request_date,
                  format: 'time',
                  timeZone: DEFAULT_TIMEZONE,
                } as any)}
              </Space>
            </>
          )}
          // width={screens.xxl ? 'auto' : 130}
        />
        <Column<UserWithithdraw>
          title={t('withdraw.table.Status')}
          dataIndex={'status_name'}
          // width={screens.xxl ? 'auto' : 100}
          render={(_, record: any) => (
            <>
              <span>{`${record.status_name}`}</span>
            </>
          )}
        />
        {/* <Column<UserWithithdraw>
          title={t('withdraw.table.ReferenceNumber')}
          dataIndex={'refrence_no'}
        /> */}
        <Column<UserWithithdraw>
          title={t('withdraw.table.action')}
          dataIndex={'last_transaction_date'}
          render={(_, record: any) => (
            <Space>
              {record.status_id == 1 ? (
                <Dropdown
                  menu={{ items: items(record.id) }}
                  trigger={['click']}
                >
                  <a onClick={(e) => e.preventDefault()}>
                    <Space className='dot_icon_drop'>
                      <MoreOutlined style={{ fontWeight: '600' }} />
                    </Space>
                  </a>
                </Dropdown>
              ) : (
                <span>No actions</span>
              )}
            </Space>
          )}
        />
      </Table>
    </StyledTableWrapper>
  );
};
