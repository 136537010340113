import { Button, Form, Spin } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { Loader, Modal, Result404 } from 'components';
import { StyledUserModalWrapper } from 'features/users/components/modals/user-modal.styles';
import { UsersWithWithdrawFilterFormField } from 'features/withdraw/components/users-with-wallet-filter-form/type';
import { useCreateSuperAdminReject } from 'features/withdraw/use-cases/create-admin-reject';
import { useGetWithdrawTransactionDetails } from 'features/withdraw/use-cases/get-withdraw-details';
export const SuperAdminEditViewModal = ({
  onClose,
  id,
  onSuccess,
}: {
  onClose: () => void;
  id: string;
  onSuccess: any;
}) => {
  // const { t } = useTranslation();
  const userQueryResult: any = useGetWithdrawTransactionDetails(`id=${id}`, {
    cacheTime: 0,
  });
  const [form] = Form.useForm();
  const {
    isLoading: isLoadingGetSocial,
    isFetching: isFetchingGetSocial,
    data: withdrawdata,
    isError: isErrorLabel,
  } = userQueryResult;
  const isLoading = {
    loading: isLoadingGetSocial || isFetchingGetSocial,
    isLoadingGetSocial,
  };
  const { mutate: mutateUpdateapproved, isLoading: mutateLoad } =
    useCreateSuperAdminReject();
  const handleSubmit = (formdata: any) => {
    const dataToSubmit = {
      id, // assuming id is defined in the scope
      //   approved: 2,
      user_name: withdrawdata?.items[0].user_name,
      phone_number: withdrawdata?.items[0].phone_number,
      session_id: withdrawdata?.items[0].session_id,
      ottu_order_no: withdrawdata?.items[0]?.ottu_order_no,
      booking_id: withdrawdata?.items[0]?.booking_id,
      refund_amount: withdrawdata?.items[0]?.amount,
      status_id: 4,
      ...formdata,
    };

    mutateUpdateapproved(dataToSubmit, { onSuccess });
  };

  return (
    <StyledUserModalWrapper>
      <Modal
        className='modal_social right-modal mtch_dtls_modal'
        open
        centered
        title={'Approve Request'}
        onCancel={() => {
          document.body.style.overflow = 'none';
          onClose();
        }}
        getContainer={false}
        footer={null}
        // onOk={{ style: { display: 'none' } }}
        // okText={t('social.update.updatescore')}
        cancelButtonProps={{ style: { display: 'none' } }}
      >
        {isLoadingGetSocial ? (
          <Loader />
        ) : isErrorLabel ? (
          <Result404 />
        ) : (
          <Spin spinning={isLoading.loading}>
            <Form onFinish={handleSubmit} form={form}>
              <div className='wd_dta_modal'>
                <div className='wd_user'>
                  <p>
                    <span>User Name:</span>
                    <span>{withdrawdata?.items[0].user_name}</span>
                  </p>
                  <p>
                    <span>Phone Number:</span>
                    <span>{withdrawdata?.items[0].phone_number}</span>
                  </p>
                  <p>
                    <span>Request Amount:</span>
                    <span>{withdrawdata?.items[0].amount} KD</span>
                  </p>
                </div>
                <div className='frm_dta'>
                  {/* <Form.Item
                    label='Reference No'
                    name={UsersWithWithdrawFilterFormField.ReferenceNumber}
                  >
                    <Input />
                  </Form.Item> */}
                  <Form.Item
                    label='Remark'
                    name={UsersWithWithdrawFilterFormField.Remark}
                  >
                    <TextArea rows={5} />
                  </Form.Item>
                </div>
                <div className='btn_grp'>
                  <Button
                    className='btn'
                    htmlType='submit'
                    loading={mutateLoad}
                  >
                    Approve
                  </Button>
                  <Button className='btn' onClick={onClose}>
                    Cancel
                  </Button>
                </div>
              </div>
            </Form>
          </Spin>
        )}
      </Modal>
    </StyledUserModalWrapper>
  );
};
